<template>
  <div class="v-filter-modal">
    <slot name="header">
      <div :class="['v-filter-modal__header', activeMenuItem && 'v-filter-modal__header--active-section']">
        <button v-if="!activeMenuItem" class="v-filter-modal__close-button" @click="closeFilters">
          <v-icon-cross />
        </button>
        <button v-else class="v-filter-modal__close-button" @click="goBack">
          <v-icon-arrow-left />
        </button>
        <div class="v-filter-modal__header-info">
          Фильтры
          <div v-if="!activeMenuItem && count" class="v-filter-modal__count">{{ count }}</div>
          <div v-else class="v-filter-modal__menu-item-name">{{ menu[activeMenuItem] }}</div>
        </div>
        <v-button-wide v-show="!activeMenuItem" class="v-filter-modal__reset-button" @click="resetFilters">
          Сброс
        </v-button-wide>
      </div>
    </slot>

    <slot />

    <slot name="footer">
      <v-button v-if="!activeMenuItem" class="v-filter-modal__button" @click="applyFilters">
        Применить
      </v-button>
    </slot>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VIconCross from '@/components/icons/VCross.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'

export default {
  name: 'VFilterModal',
  components: {
    VButton,
    VButtonWide,
    VIconCross,
    VIconArrowLeft
  },
  props: {
    activeMenuItem: {
      type: String,
      default: ''
    },
    menu: {
      type: Object,
      default: null
    },
    count: {
      type: String,
      default: ''
    }
  },
  methods: {
    applyFilters() {
      this.$emit('apply')
    },
    resetFilters() {
      this.$emit('reset')
    },
    closeFilters() {
      this.$emit('close')
    },
    goBack() {
      if (this.activeMenuItem) {
        this.$emit('update:active-menu-item', undefined)
      }
    }
  }
}
</script>
