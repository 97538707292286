import { ERROR_CODE_NETWORK_ERROR } from '@/constants/http'
import { NETWORK_ISSUE_ROUTE_NAME } from '@/constants/routes'
import router from './index'

function redirectIfNetworkIssue(error) {
  if (error.status === ERROR_CODE_NETWORK_ERROR) return router.push({ name: NETWORK_ISSUE_ROUTE_NAME })
  throw error
}

export default redirectIfNetworkIssue
