<template>
  <div class="filter-price">
    <v-form-field label="ОТ" class="filter-price__field">
      <v-input-money v-model="localPriceGte" placeholder="От 0" class="filter-price__input">
        <template #append>₽</template>
      </v-input-money>
    </v-form-field>
    <v-form-field label="ДО" class="filter-price__field">
      <v-input-money v-model="localPriceLte" placeholder="До 0" class="filter-price__input">
        <template #append>₽</template></v-input-money
      >
    </v-form-field>
  </div>
</template>

<script>
import VFormField from '@/components/form/VFormField.vue'
import VInputMoney from '@/components/common/VInputMoney.vue'

export default {
  name: 'VFilterPrice',
  components: {
    VInputMoney,
    VFormField
  },
  props: {
    priceGte: {
      type: [String, Number],
      default: ''
    },
    priceLte: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    localPriceGte: {
      get() {
        return this.priceGte
      },

      set(newValue) {
        this.$emit('update:price-gte', newValue)
      }
    },
    localPriceLte: {
      get() {
        return this.priceLte
      },

      set(newValue) {
        this.$emit('update:price-lte', newValue)
      }
    }
  }
}
</script>
