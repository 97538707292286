<template>
  <div class="v-popover-actions">
    <match-media v-slot="{ mobile }">
      <v-popover
        v-if="!mobile"
        :placement="placement"
        :open.sync="visible"
        :popover-base-class="`tooltip popover v-popover-actions__popover theme-${isNmh ? 'nmh' : 'agentik'}`"
        popover-inner-class="v-popover-actions__popover-inner"
        popover-arrow-class="v-popover-actions__popover-arrow"
      >
        <slot />
        <template #popover>
          <div class="v-popover-actions__list">
            <slot name="actions" v-bind="{ onClick }" />
          </div>
        </template>
      </v-popover>

      <div v-else class="v-popover-actions__dialog-wrapper">
        <div class="v-popover-actions__button" @click="visible = true">
          <slot />
        </div>

        <v-dialog :visible.sync="visible" hide-close-button>
          <template #body>
            <div class="v-popover-actions__list">
              <slot name="actions" v-bind="{ onClick }" />
              <v-button-wide
                class="v-popover-actions__action v-popover-actions__action--close"
                @click="visible = false"
              >
                Закрыть
              </v-button-wide>
            </div>
          </template>
        </v-dialog>
      </div>
    </match-media>
  </div>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VDialog from '@/components/common/VDialog.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'VPopoverActions',
  components: { VDialog, MatchMedia, VButtonWide },
  props: {
    placement: {
      type: String,
      default: 'auto-start'
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    })
  },
  methods: {
    onClick(cb) {
      this.visible = false
      cb()
    }
  }
}
</script>
