<template>
  <div class="overlay">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VOverlay'
}
</script>
