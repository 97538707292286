<template>
  <svg class="icon icon--dots" width="4" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.3c.7 0 1.3-.6 1.3-1.3C3.3 1.3 2.7.7 2 .7 1.3.7.7 1.3.7 2c0 .7.6 1.3 1.3 1.3zm0 1.4C1.3 4.7.7 5.3.7 6c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3zM.7 10c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VDots'
}
</script>
