<template>
  <div class="tag" :style="customColor && { background: customColor }" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
import { TAG_COLORS_ARRAY } from '@/constants/tagColors'

export default {
  name: 'VTag',
  props: {
    color: {
      type: String,
      default: TAG_COLORS_ARRAY[0],
      validator: color => TAG_COLORS_ARRAY.includes(color)
    },
    customColor: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      return { [`tag--${this.color}`]: !this.customColor }
    }
  }
}
</script>
