var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
return [_c('li',{staticClass:"realty-item"},[(_vm.$slots.overlay)?_c('v-overlay',[_vm._t("overlay")],2):_vm._e(),_c('v-square-avatar',{staticClass:"realty-item__image",attrs:{"src":_vm.photo,"size":"medium"},on:{"click":_vm.edit}}),_c('div',{staticClass:"realty-item__main"},[_c('div',{staticClass:"realty-item__content"},[_c('div',{staticClass:"realty-item__id"},[_vm._v("№ "+_vm._s(_vm.id))]),(_vm.isAgent)?_c('div',{staticClass:"realty-item__agent",class:{ 'realty-item__agent--compact-view': _vm.compactView }},[_c('v-avatar',{staticClass:"realty-item__agent-photo",attrs:{"size":"small","src":_vm.agentPhoto}}),_c('div',{staticClass:"realty-item__agent-name"},[_vm._v(_vm._s(_vm.agent.name))])],1):_vm._e(),(!_vm.hideActions && (!_vm.hideEdit || !desktop))?_c('div',{staticClass:"realty-item__actions"},[_c('v-popover-actions',{staticClass:"realty-item__mobile-actions",attrs:{"placement":"bottom-end"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var onClick = ref.onClick;
return [_vm._t("actions",[(!desktop && !_vm.isDraft)?_c('v-button-wide',{attrs:{"to":_vm.getAnalyticsPageRoute()}},[_vm._v("Аналитика")]):_vm._e(),(_vm.isRoleAdmin || _vm.isRoleAgent)?[(!_vm.hideEdit)?[_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.edit)}}},[_vm._v("Редактировать")]),(_vm.isDraft)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.remove)}}},[_vm._v("Удалить")]):_vm._e(),(_vm.isRoleAdmin && _vm.isActive)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.archive)}}},[_vm._v("Архивировать")]):_vm._e(),(_vm.isRoleAdmin && _vm.isArchived)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.activate)}}},[_vm._v("Активировать")]):_vm._e()]:_vm._e(),(!desktop)?[(_vm.ownerProfileAccount)?_c('v-button-wide',{attrs:{"to":_vm.getOwnerPageRoute(_vm.ownerProfileAccount)}},[_vm._v(" Собственник ")]):_vm._e(),(_vm.isNmh && _vm.url)?_c('v-button-wide',{attrs:{"href":_vm.url}},[_vm._v("Сайт")]):_vm._e(),(_vm.cianUrl)?_c('v-button-wide',{attrs:{"href":_vm.cianUrl}},[_vm._v("ЦИАН")]):_vm._e(),(_vm.yandexUrl)?_c('v-button-wide',{attrs:{"href":_vm.yandexUrl}},[_vm._v("Яндекс.Недвижимость")]):_vm._e(),(_vm.domClickUrl)?_c('v-button-wide',{attrs:{"href":_vm.domClickUrl}},[_vm._v("ДомКлик")]):_vm._e()]:_vm._e()]:_vm._e()],{"onClick":onClick})]}}],null,true)},[_c('v-button-icon',{staticClass:"realty-item__action"},[_c('v-icon-dots')],1)],1)],1):_vm._e(),_c('div',{staticClass:"realty-item__name"},[_c('span',{staticClass:"realty-item__name-text",on:{"click":_vm.edit}},[_vm._v(_vm._s(_vm.name))]),(_vm.advertStatus && _vm.advertStatus !== _vm.$options.ADVERT_STATUS_WITHOUT.value)?_c('v-tag-group',{attrs:{"value":_vm.advertStatus,"group":_vm.$options.ADVERT_STATUSES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,true)}):_vm._e(),(_vm.hasNotification)?_c('span',{staticClass:"realty-item__notification-identifier"}):_vm._e()],1),_c('div',{staticClass:"realty-item__info",class:{ 'realty-item__info--compact-view': _vm.compactView }},[_c('div',{staticClass:"realty-item__price"},[_vm._v(_vm._s(_vm.price))]),_c('div',{staticClass:"realty-item__plottage"},[_vm._v(_vm._s(_vm.plottage ? _vm.plottage : '–')+" сот.")]),_c('div',{staticClass:"realty-item__area"},[_vm._v(_vm._s(_vm.area ? _vm.area : '-')+" м²")]),_c('div',{staticClass:"realty-item__calls-count"},[_vm._v(_vm._s(_vm.callsCountPluralize))]),_c('div',{staticClass:"realty-item__shows-count"},[_vm._v(_vm._s(_vm.showsCountPluralize))])]),(desktop && !_vm.compactView)?_c('div',{staticClass:"realty-item__links"},[(_vm.isNmh && _vm.url)?_c('v-button',{staticClass:"realty-item__link",attrs:{"href":_vm.url,"compressed":""}},[_vm._v(" Сайт ")]):_vm._e(),(_vm.cianUrl)?_c('v-button',{staticClass:"realty-item__link",attrs:{"href":_vm.cianUrl,"compressed":""}},[_vm._v(" Циан ")]):_vm._e(),(_vm.yandexUrl)?_c('v-button',{staticClass:"realty-item__link",attrs:{"href":_vm.yandexUrl,"compressed":""}},[_vm._v(" Яндекс ")]):_vm._e(),(_vm.domClickUrl)?_c('v-button',{staticClass:"realty-item__link",attrs:{"href":_vm.domClickUrl,"compressed":""}},[_vm._v(" ДомКлик ")]):_vm._e(),((!_vm.hideActions || _vm.isRoleAdmin) && !_vm.disableLinks)?_c('v-button',{staticClass:"realty-item__link",attrs:{"compressed":"","to":_vm.getAnalyticsPageRoute()}},[_vm._v(" Аналитика ")]):_vm._e(),(_vm.ownerProfileAccount)?_c('v-button',{staticClass:"realty-item__link",attrs:{"compressed":"","to":_vm.getOwnerPageRoute(_vm.ownerProfileAccount)}},[_vm._v(" Собственник ")]):_vm._e()],1):_vm._e()])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }