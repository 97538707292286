<template>
  <match-media v-slot="{ desktop }">
    <li class="realty-item">
      <v-overlay v-if="$slots.overlay">
        <slot name="overlay"></slot>
      </v-overlay>
      <v-square-avatar class="realty-item__image" :src="photo" size="medium" @click="edit" />
      <div class="realty-item__main">
        <div class="realty-item__content">
          <div class="realty-item__id">№ {{ id }}</div>
          <div v-if="isAgent" class="realty-item__agent" :class="{ 'realty-item__agent--compact-view': compactView }">
            <v-avatar class="realty-item__agent-photo" size="small" :src="agentPhoto"></v-avatar>
            <div class="realty-item__agent-name">{{ agent.name }}</div>
          </div>
          <!--          todo: отрефакторить-->
          <div v-if="!hideActions && (!hideEdit || !desktop)" class="realty-item__actions">
            <v-popover-actions placement="bottom-end" class="realty-item__mobile-actions">
              <v-button-icon class="realty-item__action">
                <v-icon-dots />
              </v-button-icon>
              <template #actions="{ onClick }">
                <slot name="actions" :onClick="onClick">
                  <v-button-wide v-if="!desktop && !isDraft" :to="getAnalyticsPageRoute()">Аналитика</v-button-wide>
                  <template v-if="isRoleAdmin || isRoleAgent">
                    <template v-if="!hideEdit">
                      <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
                      <v-button-wide v-if="isDraft" @click="onClick(remove)">Удалить</v-button-wide>
                      <v-button-wide v-if="isRoleAdmin && isActive" @click="onClick(archive)"
                        >Архивировать</v-button-wide
                      >
                      <v-button-wide v-if="isRoleAdmin && isArchived" @click="onClick(activate)"
                        >Активировать</v-button-wide
                      >
                    </template>
                    <template v-if="!desktop">
                      <v-button-wide v-if="ownerProfileAccount" :to="getOwnerPageRoute(ownerProfileAccount)">
                        Собственник
                      </v-button-wide>
                      <v-button-wide v-if="isNmh && url" :href="url">Сайт</v-button-wide>
                      <v-button-wide v-if="cianUrl" :href="cianUrl">ЦИАН</v-button-wide>
                      <v-button-wide v-if="yandexUrl" :href="yandexUrl">Яндекс.Недвижимость</v-button-wide>
                      <v-button-wide v-if="domClickUrl" :href="domClickUrl">ДомКлик</v-button-wide>
                    </template>
                  </template>
                </slot>
              </template>
            </v-popover-actions>
          </div>
          <div class="realty-item__name">
            <span class="realty-item__name-text" @click="edit">{{ name }}</span>
            <v-tag-group
              v-if="advertStatus && advertStatus !== $options.ADVERT_STATUS_WITHOUT.value"
              :value="advertStatus"
              :group="$options.ADVERT_STATUSES_GROUP"
            >
              <template #default="{ color, text }">
                <v-tag :color="color"> {{ text }}</v-tag>
              </template>
            </v-tag-group>
            <span v-if="hasNotification" class="realty-item__notification-identifier"></span>
          </div>
          <div class="realty-item__info" :class="{ 'realty-item__info--compact-view': compactView }">
            <div class="realty-item__price">{{ price }}</div>
            <div class="realty-item__plottage">{{ plottage ? plottage : '–' }} сот.</div>
            <div class="realty-item__area">{{ area ? area : '-' }} м²</div>
            <div class="realty-item__calls-count">{{ callsCountPluralize }}</div>
            <div class="realty-item__shows-count">{{ showsCountPluralize }}</div>
          </div>
          <div v-if="desktop && !compactView" class="realty-item__links">
            <v-button v-if="isNmh && url" class="realty-item__link" :href="url" compressed>
              Сайт
            </v-button>
            <v-button v-if="cianUrl" class="realty-item__link" :href="cianUrl" compressed>
              Циан
            </v-button>
            <v-button v-if="yandexUrl" class="realty-item__link" :href="yandexUrl" compressed>
              Яндекс
            </v-button>
            <v-button v-if="domClickUrl" class="realty-item__link" :href="domClickUrl" compressed>
              ДомКлик
            </v-button>
            <v-button
              v-if="(!hideActions || isRoleAdmin) && !disableLinks"
              class="realty-item__link"
              compressed
              :to="getAnalyticsPageRoute()"
            >
              Аналитика
            </v-button>
            <v-button
              v-if="ownerProfileAccount"
              class="realty-item__link"
              compressed
              :to="getOwnerPageRoute(ownerProfileAccount)"
            >
              Собственник
            </v-button>
          </div>
        </div>
      </div>
    </li>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VAvatar from '@/components/common/VAvatar.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VSquareAvatar from '@/components/common/VSquareAvatar.vue'
import { REALTY_STATUS_ACTIVE, REALTY_STATUS_ARCHIVE, REALTY_STATUS_DRAFT } from '@/constants/statuses/realty'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { mapGetters } from 'vuex'
import { pluralizeNumeral } from '@/utils/pluralization'
import VOverlay from '@/components/common/VOverlay.vue'
import VTagGroup from '@/components/common/VTagGroup.vue'
import VTag from '@/components/common/VTag.vue'
import { ADVERT_STATUSES_GROUP, ADVERT_STATUS_WITHOUT } from '@/constants/objectRealtyOptions'
import VButton from '../common/VButton.vue'

export default {
  ADVERT_STATUSES_GROUP,
  ADVERT_STATUS_WITHOUT,
  name: 'ObjectsRealtyListItem',
  components: {
    VTag,
    VTagGroup,
    VOverlay,
    VSquareAvatar,
    MatchMedia,
    VAvatar,
    VButtonWide,
    VPopoverActions,
    VIconDots,
    VButtonIcon,
    VButton
  },
  props: {
    agent: { type: Object, default: () => {} },
    advertStatus: { type: String, default: null },
    ownerProfileAccount: { type: Number, default: null },
    price: { type: [Number, String], default: null },
    photo: { type: String, default: null },
    name: { type: String, default: null },
    id: { type: Number, default: null },
    hasNotification: { type: Boolean, default: false },
    status: { type: String, default: '' },
    cianUrl: { type: String, default: null },
    domClickUrl: { type: String, default: null },
    yandexUrl: { type: String, default: null },
    url: { type: String, default: null },
    callsCount: { type: Number, default: null },
    showsCount: { type: Number, default: null },
    viewsCount: { type: Number, default: null },
    isExtendedView: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    hideEdit: { type: Boolean, default: false },
    compactView: { type: Boolean, default: false },
    disableLinks: { type: Boolean, default: false },
    plottage: { type: Number, default: null },
    area: { type: Number, default: null }
  },
  computed: {
    agentPhoto() {
      return this.agent?.photo || ''
    },
    isActive() {
      return this.status === REALTY_STATUS_ACTIVE
    },
    isArchived() {
      return this.status === REALTY_STATUS_ARCHIVE
    },
    isDraft() {
      return this.status === REALTY_STATUS_DRAFT
    },
    isAgent() {
      return this.agent?.name && this.agent?.photo
    },
    callsCountPluralize() {
      const text = pluralizeNumeral(this.callsCount, 'лид', 'лида', 'лидов')
      return `${this.callsCount || 0} ${text}`
    },
    showsCountPluralize() {
      const text = pluralizeNumeral(this.showsCount, 'показ', 'показа', 'показов')
      return `${this.showsCount || 0} ${text}`
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isRoleAgent: `${MODULE_SESSION}/isRoleAgent`,
      isNmh: `${MODULE_SESSION}/isNmh`
    })
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    archive() {
      this.$emit('archive')
    },
    activate() {
      this.$emit('activate')
    },
    remove() {
      this.$emit('remove')
    },
    getAnalyticsPageRoute() {
      return { name: 'objects-realty-stat', params: { objectId: this.id } }
    },
    getOwnerPageRoute(ownerId) {
      return {
        name: 'owner-edit',
        params: { ownerId }
      }
    }
  }
}
</script>
