<template>
  <button :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VPaginationButton',
  props: {
    active: { type: Boolean, default: false },
    highlighted: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return [
        'pagination-button',
        this.active && 'pagination-button--active',
        this.highlighted && 'pagination-button--highlighted'
      ]
    }
  }
}
</script>
