<template>
  <svg class="icon icon--arrow-right" width="15" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1 3.8l-3.8 3.8V4.4H0V3.1h10.3V0l3.8 3.8z" />
  </svg>
</template>

<script>
export default {
  name: 'VArrowRight'
}
</script>
