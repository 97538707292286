<template>
  <div class="pagination">
    <v-pagination-button
      :class="['pagination__arrow', 'pagination__arrow--left', showLeftArrow && 'pagination__arrow--visible']"
      highlighted
      @click="change(current - 1)"
    >
      <v-icon-arrow-left />
    </v-pagination-button>

    <div class="pagination__items">
      <v-pagination-button
        v-for="item in items"
        :key="item"
        :disabled="item === $options.separator"
        :active="item === current"
        @click="change(item)"
      >
        {{ item === $options.separator ? '...' : item }}
      </v-pagination-button>
    </div>

    <v-pagination-button
      :class="['pagination__arrow', 'pagination__arrow--right', showRightArrow && 'pagination__arrow--visible']"
      highlighted
      @click="change(current + 1)"
    >
      <v-icon-arrow-right />
    </v-pagination-button>
  </div>
</template>

<script>
import VPaginationButton from '@/components/common/VPaginationButton.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'
import VIconArrowRight from '@/components/icons/VArrowRight.vue'

const SEPARATOR_VALUE = null

export default {
  name: 'VPagination',
  components: { VPaginationButton, VIconArrowLeft, VIconArrowRight },
  props: {
    current: { type: Number, required: true },
    total: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
    isCompact: { type: Boolean, default: false }
  },
  separator: SEPARATOR_VALUE,
  inject: ['mediaQueries'],
  computed: {
    totalLength() {
      return this.mediaQueries.mobile ? 6 : 7
    },
    centerLength() {
      return this.totalLength - 4
    },
    insideHalfLength() {
      return Math.floor(this.centerLength / 2)
    },
    showLeftArrow() {
      return this.current > 1
    },
    showRightArrow() {
      return this.current < this.total
    },
    items() {
      if (this.isCompact) return []
      if (this.total <= this.totalLength) {
        return Array.from({ length: this.total }, (_, index) => index + 1)
      }

      const from = Math.max(this.current - this.insideHalfLength, 1)
      const to = Math.min(this.current + this.insideHalfLength, this.total)

      const items = Array.from({ length: this.centerLength }, (el, index) => {
        if (to !== this.total) return index + from
        return index + (this.total - this.centerLength)
      })

      if (items[0] >= 2) {
        if (items[0] > 2) {
          if (this.total - this.current <= 2) {
            while (items.length < this.totalLength - 3) {
              items.unshift(items[0] - 1)
            }
          }
          items.unshift(this.$options.separator)
        }
        items.unshift(1)
      }

      if (items[items.length - 1] < this.total) {
        if (items[items.length - 1] < this.total - 2) {
          while (items.length < this.totalLength - 2) {
            items.push(items[items.length - 1] + 1)
          }
          items.push(this.$options.separator)
        } else if (items[items.length - 1] === this.total - 2) items.push(this.total - 1)
        items.push(this.total)
      }

      return items
    }
  },
  methods: {
    change(page) {
      this.$emit('change', page)
    }
  }
}
</script>
