<template>
  <div class="icon-plus">
    <div :class="classes"></div>
  </div>
</template>

<script>
const SIZE = ['small', 'medium']
export default {
  name: 'VPlus',
  props: {
    size: {
      type: String,
      default: 'small',
      validator: size => SIZE.includes(size)
    }
  },
  computed: {
    classes() {
      return ['icon-plus__elem', `icon-plus__elem--${this.size}`]
    }
  }
}
</script>

<style lang="scss">
.icon-plus {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__elem {
    width: 2px;
    position: relative;
    background-color: $--white;

    &::after {
      content: '';
      height: 2px;
      display: block;
      position: absolute;
      background-color: $--white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--small {
      height: 14px;

      &::after {
        width: 14px;
      }
    }

    &--medium {
      height: 19px;

      &::after {
        width: 19px;
      }
    }
  }
}
</style>
