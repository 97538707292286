<template>
  <div class="date-publication">
    <v-form-field label="ОТ" class="date-publication__field" separate-label>
      <v-input-date v-model="localDateFrom" class="date-publication__input-date" inline />
    </v-form-field>
    <v-form-field label="ДО" class="date-publication__field" separate-label>
      <v-input-date v-model="localDateTo" class="date-publication__input-date" inline />
    </v-form-field>
  </div>
</template>

<script>
import VInputDate from '@/components/common/VInputDate.vue'
import VFormField from '@/components/form/VFormField.vue'

export default {
  name: 'VDatePublication',
  components: {
    VInputDate,
    VFormField
  },
  props: {
    dateFrom: {
      type: String,
      default: ''
    },
    dateTo: {
      type: String,
      default: ''
    }
  },
  computed: {
    localDateFrom: {
      get() {
        return this.dateFrom
      },

      set(newValue) {
        this.$emit('update:date-from', newValue)
      }
    },
    localDateTo: {
      get() {
        return this.dateTo
      },

      set(newValue) {
        this.$emit('update:date-to', newValue)
      }
    }
  }
}
</script>
