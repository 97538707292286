<template>
  <validation-provider
    v-slot="{ errors, validate }"
    class="form-field"
    tag="div"
    :mode="validationMode"
    v-bind="$attrs"
  >
    <template v-if="separateLabel">
      <label>
        <span v-if="label" :class="classes">{{ label }}</span>
      </label>
      <slot :validate="validate" :validation-errors="errorMessage ? [errorMessage, ...errors] : errors"></slot>
    </template>
    <template v-else>
      <label>
        <span v-if="label" :class="classes">{{ label }}</span>
        <slot :validation-errors="errorMessage ? [errorMessage, ...errors] : errors"></slot>
      </label>
    </template>

    <slot v-if="!hideErrors" name="error" :errorMessage="errors[0] || errorMessage">
      <v-form-error>
        {{ errors[0] || errorMessage }}
      </v-form-error>
    </slot>
  </validation-provider>
</template>

<script>
import VFormError from '@/components/form/VFormError.vue'

export default {
  name: 'VFormField',
  components: { VFormError },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelSize: {
      type: String,
      default: 'small',
      validator: size => ['medium', 'small'].includes(size)
    },
    separateLabel: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    },
    hideErrors: {
      type: Boolean,
      default: false
    },
    validationMode: {
      type: String,
      default: 'eager'
    }
  },
  computed: {
    classes() {
      return ['form-field__title', `form-field__title--${this.labelSize}`]
    }
  }
}
</script>
