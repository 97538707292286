<template>
  <a v-if="href" class="button-wide" :href="href" target="_blank">
    <slot></slot>
  </a>
  <router-link v-else-if="to" class="button-wide" :to="to" v-on="$listeners">
    <slot></slot>
  </router-link>
  <button v-else class="button-wide" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButtonWide',
  props: {
    href: { type: String, default: null },
    to: { type: [String, Object], default: null }
  }
}
</script>
