<template>
  <div :class="classes" v-on="$listeners">
    <img v-if="hasAvatar" :src="src" class="square-avatar__img" alt="Object avatar" />
    <img v-else class="square-avatar__img" src="@/assets/images/logo_black.jpg" alt="Default logo" />
  </div>
</template>

<script>
import AVATAR_SIZES from '@/constants/avatarSize'

export default {
  name: 'VSquareAvatar',
  props: {
    src: { type: String, default: null },
    size: { type: String, default: 'small', validate: val => AVATAR_SIZES.includes(val) }
  },
  computed: {
    classes() {
      return ['square-avatar', `square-avatar--${this.size}`]
    },
    hasAvatar() {
      return !!this.src
    }
  }
}
</script>
