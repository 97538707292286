<template>
  <div class="tag-group">
    <slot :color="color" :text="text"></slot>
  </div>
</template>

<script>
export default {
  name: 'VTagGroup',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    color() {
      return this.group[this.value]?.color || 'light-gray'
    },
    text() {
      return this.group[this.value]?.text || ''
    }
  }
}
</script>
