<template>
  <filters-select
    v-model="selectedAgent"
    class="mobile-filter-select-agent"
    v-bind="{ isCustomMarker: true }"
    :options="options"
  >
    <template #append="{ isSelected }">
      <v-tick v-if="isSelected" class="mobile-filter-select-agent__icon" />
    </template>
  </filters-select>
</template>

<script>
import FiltersSelect from '@/components/Agent/AgentsSelect.vue'
import VTick from '@/components/icons/VTick.vue'

export default {
  name: 'MobileFiltersSelectAgent',
  components: {
    FiltersSelect,
    VTick
  },
  props: {
    value: {
      type: [Array, Object],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectedAgent: {
      get() {
        return this.value
      },
      set(newAgent) {
        this.$emit('input', newAgent)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/mixins';

.mobile-filter-select-agent {
  &__icon {
    width: 20px;

    @include themify() {
      color: themed('iconTick');
    }
  }
}
</style>
