<template>
  <v-input
    :value="localValue"
    :mask="moneyMask"
    inputmode="numeric"
    :is-error="isError"
    v-bind="$attrs"
    v-on="inheritListeners"
    @input="updateMoneyValue"
  >
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </v-input>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import VInput from '@/components/common/VInput.vue'

export default {
  name: 'VInputMoney',
  components: {
    VInput
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    suffix: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    thousandsSeparatorSymbol: {
      type: String,
      default: ' '
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: ''
    }
  },
  computed: {
    inheritListeners() {
      const { input: _input, ...restListeners } = this.$listeners
      return restListeners
    },
    moneyValue() {
      return this.localValue.replaceAll(this.thousandsSeparatorSymbol, '')
    },
    moneyMask() {
      // https://www.npmjs.com/package/text-mask-addons - other function parameters
      return createNumberMask({
        prefix: this.prefix,
        suffix: this.suffix,
        thousandsSeparatorSymbol: this.thousandsSeparatorSymbol
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.localValue = newValue
      }
    }
  },
  methods: {
    updateMoneyValue(value) {
      this.localValue = value
      this.$emit('input', String(this.moneyValue))
    }
  }
}
</script>
