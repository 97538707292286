<template>
  <span class="form-field__error">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'VFromError'
}
</script>
